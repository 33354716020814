import { useSelect } from "@refinedev/antd";
import { Select } from "antd";

const BranchSelect = ({ value, onChange, ...otherProps }) => {
  const { selectProps } = useSelect({
    resource: "branches",
    optionLabel: "name",
    optionValue: "id",
    pagination: { mode: "off" },
    onSearch: (value) => [{ field: "name", operator: "contains", value }],
  });

  return (
    <Select
      {...selectProps}
      value={value}
      onChange={onChange}
      allowClear
      {...otherProps}
    />
  );
};

export default BranchSelect;
