import { List } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { Col, Form, Row, Skeleton, Space } from "antd";
import AreaChart from "components/chart/AreaChart";
import ChartFilter from "components/chart/ChartFilter";
import HorizontalBarChart from "components/chart/HorizontalBarChart";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const ActiveEnergyAnalysisReport = () => {
  const [filters, setFilters] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [dateKey, setDateKey] = useState(null);
  const [areaChartData, setAreaChartData] = useState([]);
  const [form] = Form.useForm();

  /**
   * Get Room Daily Active Energy Data
   */
  const { data: rawDaily, isLoading: isRawDailyLoading } = useList({
    resource: "room_daily_active_energy",
    filters,
    pagination: { mode: "off" },
    queryOptions: { enabled: filters.length > 0 },
  });

  const { data: dataDaily = [] } = rawDaily ?? {};
  const dataTotal = Object.values(
    dataDaily?.reduce((acc, cur) => {
      const key = `${cur?.roomId}`;
      acc[key] = acc[key] || { key, label: cur?.roomName, value: 0 };
      acc[key].value += cur?.dailyActiveEnergy;
      return acc;
    }, {})
  ).sort((a, b) => b.value - a.value);

  /**
   * Get Room Hourly Active Energy Data
   */
  const { data: rawHourly, refetch: refetchRawHourly } = useList({
    resource: "room_hourly_active_energy",
    filters: [
      { field: "roomId", operator: "eq", value: roomId },
      {
        field: "usageDate",
        operator: "gte",
        value: dayjs(dateKey ?? "1970-01-01").format("YYYY-MM-DD"),
      },
      {
        field: "usageDate",
        operator: "lte",
        value: dayjs(dateKey ?? "1970-01-01").format("YYYY-MM-DD"),
      },
    ],
    pagination: { mode: "off" },
    queryOptions: { enabled: roomId > 0 && !!dateKey },
  });

  /**
   * Group data by hour regardless of power meter
   * (after filtered by roomId and usageDate)
   */
  const dataHourly = Object.values(
    (rawHourly?.data ?? []).reduce((acc, cur) => {
      const key = cur?.usageHour;
      acc[key] = acc[key] || { key, label: key, value: 0 };
      acc[key].value += cur?.hourlyActiveEnergy;
      return acc;
    }, {})
  );

  useEffect(() => {
    const dataArea = Object.values(
      dataDaily
        ?.filter((item) => item?.roomId + "" === roomId)
        .reduce((acc, cur) => {
          const dt = dayjs(cur?.usageDate);
          const key = dt.format("YYYYMMDD");
          acc[key] = acc[key] || { key, label: dt.format("D MMM"), value: 0 };
          acc[key].value += cur?.dailyActiveEnergy;
          return acc;
        }, {})
    ).sort((a, b) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0;
    });

    setAreaChartData(dataArea);
  }, [roomId, dataDaily]);

  useEffect(() => {
    refetchRawHourly?.();
  }, [dateKey]);

  const onFinish = ({ usageDate, ...rest }) => {
    let newFilter = [
      ...Object.keys(rest).map((field) => ({
        field,
        operator: "eq",
        value: rest[field],
      })),
      { field: "usageDate", operator: "gte", value: usageDate?.[0] },
      { field: "usageDate", operator: "lte", value: usageDate?.[1] },
    ];
    setFilters(newFilter);
    setRoomId(0);
    setDateKey(null);
  };

  const onRoomClick = (element) => {
    if (element?.length) {
      setRoomId(dataTotal[element[0].index].key);
    } else {
      setRoomId(0);
    }
    setDateKey(null);
  };

  const onDateClick = (element) => {
    if (element?.length) {
      setDateKey(areaChartData[element[0].index].key);
    } else {
      setDateKey(null);
    }
  };

  const roomName = dataTotal?.find((item) => item.key === roomId)?.label;

  return (
    <List title="Active Energy Analysis">
      <Space direction="vertical" style={{ display: "flex" }}>
        <ChartFilter
          formProps={{ form }}
          onFinish={onFinish}
          submitButtonProps={{ loading: isRawDailyLoading }}
        />

        {isRawDailyLoading ? (
          <Skeleton paragraph={{ rows: 6 }} active />
        ) : (
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={8}>
              <HorizontalBarChart
                title="Total Active Energy"
                data={dataTotal}
                datasetProps={{ label: "Active Energy (kWh)" }}
                onClick={onRoomClick}
                cardProps={{ style: { height: "100%" } }}
              />
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Space direction="vertical" style={{ display: "flex" }}>
                <AreaChart
                  title={
                    "Daily Active Energy" + (roomName ? ` - ${roomName}` : "")
                  }
                  data={areaChartData}
                  datasetProps={{ label: "Active Energy (kWh)" }}
                  onClick={onDateClick}
                  cardProps={{
                    bodyStyle: { width: "100%" },
                  }}
                  emptyMessage={
                    !roomId ? "Please select room from chart" : "No data"
                  }
                />
                <AreaChart
                  title={
                    "Hourly Active Energy" +
                    (roomName ? ` - ${roomName}` : "") +
                    (dateKey
                      ? ` on ${dayjs(dateKey).format("D MMM YYYY")}`
                      : "")
                  }
                  data={dataHourly}
                  datasetProps={{ label: "Active Energy (kWh)" }}
                  cardProps={{
                    bodyStyle: { width: "100%" },
                  }}
                  emptyMessage={
                    !dateKey ? "Please select date from chart" : "No data"
                  }
                />
              </Space>
            </Col>
          </Row>
        )}
      </Space>
    </List>
  );
};
