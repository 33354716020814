import {
  CaretDownFilled,
  CloudOutlined,
  DashboardOutlined,
  RedoOutlined,
  TeamOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { useList } from "@refinedev/core";
import {
  List as AntdList,
  Button,
  Card,
  Col,
  Dropdown,
  Grid,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import AreaChart from "components/chart/AreaChart";
import { HumidityIcon } from "components/icons";
import RoomStatusAvatar from "components/room/RoomStatusAvatar";
import NumberText from "components/shared/NumberText";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const { useBreakpoint } = Grid;

const measurements = [
  {
    key: "totalActivePower",
    label: "Active Power",
    uom: "W",
  },
  { key: "averageRoomTemperature", label: "Temperature", uom: `\u00b0C` },
  { key: "averageCo2", label: "CO2", uom: `ppm` },
  { key: "averageHumidity", label: "Humidity", uom: `%` },
];

const showMeasurement = (key, value) => {
  const { uom } = measurements.find((item) => item.key === key) ?? {};

  return (
    <Space size={2} align="baseline">
      <NumberText>
        {value == null ? "-" : Math.round(value * 10) / 10}
      </NumberText>
      {value != null && (
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {uom}
        </Typography.Text>
      )}
    </Space>
  );
};

export const DashboardPage = () => {
  const [branch, setBranch] = useState({});
  const [roomReading, setRoomReading] = useState("totalActivePower");
  const [statusFilter, setStatusFilter] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [summary, setSummary] = useState({});
  const screens = useBreakpoint();

  // Get Branches data for the dropdown
  const { data: dataBranches } = useList({
    resource: "branches",
    pagination: { mode: "off" },
    sorters: [{ field: "name", order: "asc" }],
    queryOptions: {
      onSuccess: ({ data }) => {
        if (data.length) {
          // Set the first branch (order by ID) as default selected branch
          // without affecting the sorting order (by name)
          const [{ id, name }] = [...data].sort((a, b) => a.id - b.id);
          setBranch({ id, name });
        }
      },
    },
  });
  const branches = dataBranches?.data ?? [];

  // Get [Room Status] data
  const {
    data: dataRoomStatus,
    refetch: refetchRoomStatus,
    isLoading: isRoomStatusLoading,
  } = useList({
    resource: "room_current_status",
    pagination: { mode: "off" },
    filters: [{ field: "branchId", operator: "eq", value: branch?.id }],
    sorters: [{ field: roomReading, order: "desc" }],
  });
  // Count total rooms for each status
  const roomStatus = dataRoomStatus?.data?.reduce((acc, cur) => {
    const key = cur?.status;

    acc[key] = acc[key] || 0;
    acc[key] += 1;
    return acc;
  }, {});

  // Get hourly active energy
  const {
    data: dataTodayEnergy,
    isLoading: isTodayEnergyLoading,
    refetch: refetchTodayEnergy,
  } = useList({
    resource: "room_hourly_active_energy",
    filters: [
      { field: "branchId", operator: "eq", value: branch?.id },
      {
        field: "usageDate",
        operator: "gte",
        value: dayjs().format("YYYY-MM-DD"),
      },
      {
        field: "usageDate",
        operator: "lte",
        value: dayjs().format("YYYY-MM-DD"),
      },
    ],
    queryOptions: {
      onSuccess: ({ data }) =>
        setSummary((prev) => ({
          ...prev,
          totalActiveEnery: data?.reduce(
            (acc, cur) => acc + (cur?.hourlyActiveEnergy ?? 0),
            0
          ),
        })),
    },
  });

  const { isLoading: isTodayReadingsLoading, refetch: refetchTodayReadings } =
    useList({
      resource: "room_daily_readings",
      filters: [
        { field: "branchId", operator: "eq", value: branch?.id },
        {
          field: "usageDate",
          operator: "gte",
          value: dayjs().format("YYYY-MM-DD"),
        },
        {
          field: "usageDate",
          operator: "lte",
          value: dayjs().format("YYYY-MM-DD"),
        },
      ],
      queryOptions: {
        onSuccess: ({ data }) => {
          const result = [
            "totalRoomTemperature",
            "totalHumidity",
            "totalCo2",
          ].map((key) => {
            return data?.reduce(
              (acc, cur) => {
                acc[0] += cur?.[key] ?? 0;
                acc[1] += cur?.totalRow ?? 0;
                return acc;
              },
              [0, 0]
            );
          });

          const averages = result.map(([total, count]) => {
            if (count > 0) return total / count;
            return 0;
          });

          setSummary((prev) => ({
            ...prev,
            averageRoomTemperature: Math.round(averages[0] * 10) / 10,
            averageHumidity: Math.round(averages[1] * 10) / 10,
            averageCo2: Math.round(averages[2] * 10) / 10,
          }));
        },
      },
    });

  const chartData = "0"
    .repeat(24)
    .split("")
    .map((str, idx) => {
      const label = parseInt(str) + idx;
      const value = dataTodayEnergy?.data
        ?.filter((row) => row?.usageHour === label)
        ?.reduce((acc, cur) => acc + (cur?.hourlyActiveEnergy ?? 0), 0);
      return { label, value };
    });

  useEffect(() => {
    const timer = setInterval(() => {
      setIsRefreshing(true);
      setTimeout(() => {
        refetchRoomStatus?.();
        refetchTodayEnergy?.();
        refetchTodayReadings?.();
        setIsRefreshing(false);
      }, 300);
    }, 15 * 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      {/* Branch Dropdown */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Dropdown
          menu={{
            items: branches.map(({ id, name }) => ({
              key: id,
              label: name,
              onClick: () => setBranch({ id, name }),
            })),
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space align="baseline">
              <Typography.Title level={4} style={{ margin: 0, paddingLeft: 4 }}>
                {branch?.name}
              </Typography.Title>
              <CaretDownFilled style={{ fontSize: 16, color: "black" }} />
            </Space>
          </a>
        </Dropdown>

        {/* Refresh Button */}
        <Button
          type="text"
          icon={<RedoOutlined />}
          size="small"
          loading={isRefreshing || isRoomStatusLoading || isTodayEnergyLoading}
          onClick={() => {
            setIsRefreshing(true);
            setTimeout(() => {
              refetchRoomStatus?.();
              refetchTodayEnergy?.();
              refetchTodayReadings?.();
              setIsRefreshing(false);
            }, 300);
          }}
        >
          Refresh
        </Button>
      </div>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={17} lg={17} xl={17}>
          <Space direction="vertical" size={16} style={{ display: "flex" }}>
            <Row gutter={[8, 8]}>
              {["In Used", "Not In Use", "Offline"].map((status, idx) => {
                const border =
                  status === statusFilter ? { borderColor: "#1677ff" } : {};
                const background = !screens.lg
                  ? {}
                  : {
                      backgroundImage: "url('images/house.png')",
                      backgroundSize: "contain",
                      backgroundPosition: "100% 100%",
                      backgroundRepeat: "no-repeat",
                    };

                return (
                  <Col key={`${idx}`} span={8}>
                    <Card
                      hoverable
                      size="small"
                      style={{
                        width: "100%",
                        ...background,
                        ...border,
                      }}
                      onClick={() =>
                        setStatusFilter((prev) =>
                          prev === status ? null : status
                        )
                      }
                    >
                      <Space
                        direction="vertical"
                        size={0}
                        style={{ display: "flex" }}
                      >
                        <Typography.Text>{status}</Typography.Text>
                        <Space align="center">
                          <RoomStatusAvatar status={status} />
                          <Typography.Text
                            style={{ fontSize: 28, fontWeight: 600 }}
                          >
                            {roomStatus?.[status] ?? 0}
                          </Typography.Text>
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={5} lg={5} xl={6} xxl={6}>
                <Card size="small" style={{ width: "100%", height: "100%" }}>
                  <Space direction="vertical" size={16}>
                    <Typography.Title level={5}>Today</Typography.Title>
                    <Statistic
                      title="Active Energy"
                      value={
                        Math.round(summary?.totalActiveEnery * 1000) / 1000 ??
                        "-"
                      }
                      prefix={
                        <ThunderboltOutlined style={{ marginRight: 2 }} />
                      }
                      suffix={
                        <Typography.Text type="secondary">kWh</Typography.Text>
                      }
                      loading={isTodayEnergyLoading}
                    />
                    <Statistic
                      title="Average Temperature"
                      value={summary?.averageRoomTemperature ?? "-"}
                      prefix={<DashboardOutlined style={{ marginRight: 2 }} />}
                      suffix={
                        <Typography.Text type="secondary">
                          {`\u00b0C`}
                        </Typography.Text>
                      }
                      loading={isTodayReadingsLoading}
                    />
                    <Statistic
                      title="Average CO2"
                      value={summary?.averageCo2 ?? "-"}
                      prefix={<CloudOutlined style={{ marginRight: 2 }} />}
                      suffix={
                        <Typography.Text type="secondary">ppm</Typography.Text>
                      }
                      loading={isTodayReadingsLoading}
                    />
                    <Statistic
                      title="Average Humidity"
                      value={summary?.averageHumidity ?? "-"}
                      prefix={<HumidityIcon style={{ marginRight: 2 }} />}
                      suffix={
                        <Typography.Text type="secondary">%</Typography.Text>
                      }
                      loading={isTodayReadingsLoading}
                    />
                  </Space>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={19} lg={19} xl={18} xxl={18}>
                <AreaChart
                  title="Hourly Active Energy"
                  data={chartData}
                  datasetProps={{
                    label: "Active Energy (kWh)",
                    backgroundColor: "rgba(102, 106, 246, 0.7)",
                  }}
                  maintainAspectRatio={true}
                  scales={{ x: { title: { display: true, text: "Hour" } } }}
                  cardProps={{ style: { height: "100%" } }}
                />
              </Col>
            </Row>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
          <Card
            size="small"
            style={{ width: "100%", height: "100%" }}
            cover={
              <img
                src="/images/socket.png"
                style={{ objectFit: "contain" }}
                height="150px"
                width="auto"
              />
            }
          >
            <Space direction="vertical" style={{ display: "flex" }} size={0}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  marginTop: -10,
                }}
              >
                <Typography.Title level={5}>Current Reading</Typography.Title>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: measurements.map((item) => ({
                      ...item,
                      onClick: ({ key }) => setRoomReading(key),
                    })),
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {
                        measurements.find(({ key }) => key === roomReading)
                          ?.label
                      }
                      <CaretDownFilled />
                    </Space>
                  </a>
                </Dropdown>
              </div>

              <AntdList
                dataSource={
                  dataRoomStatus?.data?.filter(({ status }) => {
                    if (statusFilter) return status === statusFilter;
                    return true;
                  }) ?? []
                }
                pagination={{
                  pageSize: 10,
                  size: "small",
                  simple: true,
                  hideOnSinglePage: true,
                }}
                style={{ padding: 4 }}
                rowKey="roomId"
                renderItem={(item) => (
                  <AntdList.Item
                    extra={showMeasurement(roomReading, item?.[roomReading])}
                  >
                    <AntdList.Item.Meta
                      avatar={<RoomStatusAvatar status={item?.status} />}
                      title={
                        <Space>
                          {item?.roomName}
                          {item?.maxMotionDetected ? <TeamOutlined /> : null}
                        </Space>
                      }
                    />
                  </AntdList.Item>
                )}
              />
            </Space>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};
