import { BulbFilled } from "@ant-design/icons";
import { Avatar } from "antd";
import React from "react";

const getBgColor = (status) => {
  switch (status) {
    case "In Used":
      return "#f9f018";
    case "Not In Use":
      return "#1890ff";
    default:
      return "#db4437";
  }
};

const RoomStatusAvatar = ({
  status,
  style = {},
  iconProps = {},
  ...otherProps
}) => {
  return (
    <Avatar
      style={{ backgroundColor: getBgColor(status), ...style }}
      icon={<BulbFilled {...iconProps} />}
      {...otherProps}
    />
  );
};

export default React.memo(RoomStatusAvatar);
