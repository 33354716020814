import { getDefaultSortOrder, useTable } from "@refinedev/antd";
import { List, Table } from "antd";

export const UserList = () => {
  const { tableProps, sorters: sorter } = useTable();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="email"
          title="Email"
          sorter
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
        />
        <Table.Column
          dataIndex="firstName"
          title="First Name"
          sorter
          defaultSortOrder={getDefaultSortOrder("firstName", sorter)}
        />
        <Table.Column
          dataIndex="lastName"
          title="Last Name"
          sorter
          defaultSortOrder={getDefaultSortOrder("lastName", sorter)}
        />
        <Table.Column
          dataIndex="phoneNumber"
          title="Phone Number"
          sorter
          defaultSortOrder={getDefaultSortOrder("phoneNumber", sorter)}
        />
        <Table.Column
          dataIndex="organizationName"
          title="Organization Name"
          sorter
          defaultSortOrder={getDefaultSortOrder("organizationName", sorter)}
        />
      </Table>
    </List>
  );
};
