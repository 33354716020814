import { Form, Input, InputNumber, Select, Space, Typography } from "antd";
import RoomSelect from "components/room/RoomSelect";
import pluralize from "pluralize";
import React from "react";
import { FORM_RULES, SWITCH_TYPES } from "../../constants";

const SwitchForm = ({ ...formProps }) => {
  const initRoomId = formProps?.initialValues?.roomId;
  const swicthType = Form.useWatch("type", formProps?.form) ?? "";
  const onMins = Form.useWatch("autoOnTimer", formProps?.form) ?? 0;
  const offMins = Form.useWatch("autoOffTimer", formProps?.form) ?? 0;
  const isAC = swicthType === "Air Conditioner";

  return (
    <Form {...formProps}>
      <Form.Item
        name="roomId"
        label="Room"
        rules={[FORM_RULES.required]}
        hidden={initRoomId > 0}
      >
        <RoomSelect />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[FORM_RULES.required]}>
        <Input maxLength={80} />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[FORM_RULES.required]}>
        <Select
          options={SWITCH_TYPES.map((value) => ({ label: value, value }))}
        />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea maxLength={200} rows={3} />
      </Form.Item>
      <Form.Item
        name="aircondCelsius"
        label="A/C Temperature"
        rules={isAC ? [FORM_RULES.required] : []}
        hidden={!isAC}
      >
        <InputNumber
          min={16}
          max={35}
          addonAfter={`\u00b0 C`}
          style={{ width: 145 }}
        />
      </Form.Item>
      <Form.Item label="Auto On After" required={true}>
        <Space>
          <Form.Item name="autoOnTimer" rules={[FORM_RULES.required]} noStyle>
            <InputNumber
              min={0}
              max={30}
              addonAfter={pluralize("minute", onMins, false)}
              style={{ width: 145 }}
            />
          </Form.Item>
          <Typography.Text type="secondary">
            0 to turn on immediately
          </Typography.Text>
        </Space>
      </Form.Item>
      <Form.Item label="Auto Off After" required>
        <Space>
          <Form.Item name="autoOffTimer" rules={[FORM_RULES.required]} noStyle>
            <InputNumber
              min={0}
              max={30}
              addonAfter={pluralize("minute", offMins, false)}
              style={{ width: 145 }}
            />
          </Form.Item>
          <Typography.Text type="secondary">
            0 to turn off immediately
          </Typography.Text>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default React.memo(SwitchForm);
