import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useCan, useDelete, useNavigation } from "@refinedev/core";
import { Dropdown, Modal } from "antd";
import React from "react";

const { confirm } = Modal;

const MoreDropDown = ({
  resource,
  id,
  extra = [],
  viewButtonProps = {},
  editButtonProps = {},
  deleteButtonProps = {},
  iconProps = {},
}) => {
  const { show, edit } = useNavigation();
  const { mutate: mutateDelete } = useDelete();
  const { data: canEdit } = useCan({
    resource,
    action: "edit",
  });
  const { data: canDelete } = useCan({
    resource,
    action: "delete",
  });

  const items = [
    {
      key: "view",
      label: "View",
      icon: <EyeOutlined />,
      onClick: () => show(resource, id),
      ...viewButtonProps,
    },
  ];

  if (canEdit) {
    items.push({
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: () => edit(resource, id),
      ...editButtonProps,
    });
  }

  if (canDelete) {
    items.push({
      key: "delete",
      label: "Delete",
      icon: <DeleteOutlined />,
      danger: !deleteButtonProps?.disabled,
      onClick: () =>
        confirm({
          icon: <QuestionCircleOutlined />,
          content: "Are you sure want to delete this record?",
          okText: "Delete",
          okButtonProps: { danger: true },
          onOk: () => mutateDelete({ resource, id }),
        }),
      ...deleteButtonProps,
    });
  }

  if (extra.length) {
    items.splice(0, 0, ...extra, { type: "divider" });
  }

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <MoreOutlined {...iconProps} />
    </Dropdown>
  );
};

export default React.memo(MoreDropDown);
