import { List } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { Card, Col, Form, Row, Segmented, Skeleton, Space } from "antd";
import ChartFilter from "components/chart/ChartFilter";
import HorizontalBarChart from "components/chart/HorizontalBarChart";
import MinMaxAverageChart from "components/chart/MinMaxAverageChart";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const readingOptions = [
  { label: "Room Temperature", value: "RoomTemperature", uom: `\u00b0C` },
  { label: "Carbon Dioxide", value: "Co2", uom: "ppm" },
  { label: "Humidity", value: "Humidity", uom: "%" },
];

const measurementOptions = [
  { label: "Lowest", value: "min" },
  { label: "Highest", value: "max" },
  { label: "Average", value: "average" },
];

export const RoomReadingAnalysisReport = () => {
  const [reading, setReading] = useState("RoomTemperature");
  const [measurement, setMeasurement] = useState("average");
  const [filters, setFilters] = useState([]);
  const [roomId, setRoomId] = useState(0);
  const [dateKey, setDateKey] = useState(null);
  const [form] = Form.useForm();

  const { data: rawDaily, isLoading: isRawDailyLoading } = useList({
    resource: "room_daily_readings",
    filters,
    pagination: { mode: "off" },
    queryOptions: { enabled: filters.length > 0 },
  });

  const { data: rawHourly, refetch: refetchRawHourly } = useList({
    resource: "room_hourly_readings",
    filters: [
      {
        field: "usageDate",
        operator: "gte",
        value: dayjs(dateKey ?? "1970-01-01").format("YYYY-MM-DD"),
      },
      {
        field: "usageDate",
        operator: "lte",
        value: dayjs(dateKey ?? "1970-01-01").format("YYYY-MM-DD"),
      },
      { field: "roomId", operator: "eq", value: roomId },
    ],
    sorters: [{ field: "usageHour", order: "asc" }],
    queryOptions: { enabled: !!dateKey && roomId > 0 },
  });

  useEffect(() => {
    refetchRawHourly?.();
  }, [dateKey]);

  /**
   * Group data by Room
   */
  const dataRooms = Object.values(
    rawDaily?.data?.reduce((acc, cur) => {
      const key = cur?.roomId;
      const curMin = cur?.[`min${reading}`];
      const curMax = cur?.[`max${reading}`];

      acc[key] = acc[key] || {
        key,
        label: cur?.roomName,
        min: curMin,
        max: curMax,
        total: 0,
        count: 0,
      };

      acc[key].min = Math.min(acc[key].min, curMin);
      acc[key].max = Math.max(acc[key].min, curMax);
      acc[key].total += cur?.[`total${reading}`] ?? 0;
      acc[key].count += cur?.totalRow ?? 0;

      return acc;
    }, {}) ?? {}
  )
    .map((item) => ({
      // Calculate average
      ...item,
      average:
        item?.count > 0 ? Math.round((item?.total / item?.count) * 10) / 10 : 0,
    }))
    .map(({ key, label, ...rest }) => ({
      // Map to chart data format
      key,
      label,
      value: rest[measurement],
    }))
    .sort((a, b) => {
      // Sort value by descending (max & avg) or ascending (min)
      if (measurement === "min") return a.value - b.value;
      return b.value - a.value;
    });

  /**
   * Group data by Date filter by Room
   */
  const dataDaily = rawDaily?.data
    ?.filter((item) => item?.roomId === roomId)
    .map((row) => {
      const dt = dayjs(row?.usageDate);
      return {
        key: dt.format("YYYYMMDD"),
        label: dt.format("D MMM"),
        min: row[`min${reading}`],
        max: row[`max${reading}`],
        average: row[`average${reading}`],
      };
    });

  const onFinish = ({ usageDate, ...rest }) => {
    let newFilter = [
      ...Object.keys(rest).map((field) => ({
        field,
        operator: "eq",
        value: rest[field],
      })),
      { field: "usageDate", operator: "gte", value: usageDate?.[0] },
      { field: "usageDate", operator: "lte", value: usageDate?.[1] },
    ];
    setFilters(newFilter);
    setRoomId(0);
    setDateKey(null);
  };

  const onRoomClick = (element) => {
    if (element?.length) {
      setRoomId(dataRooms[element[0].index].key);
    } else {
      setRoomId(0);
    }
    setDateKey(null);
  };

  const onDateClick = (element) => {
    if (element?.length) {
      setDateKey(dataDaily[element[0].index].key);
    } else {
      setDateKey(null);
    }
  };

  // Get labels
  const selected = readingOptions.find(({ value }) => reading === value);
  const roomName = dataRooms?.find((item) => item.key === roomId)?.label;

  return (
    <List title="Room Reading Analysis">
      <Space direction="vertical" size={16} style={{ display: "flex" }}>
        <ChartFilter
          formProps={{ form }}
          onFinish={onFinish}
          submitButtonProps={{ loading: isRawDailyLoading }}
        />
        {isRawDailyLoading ? (
          <Skeleton paragraph={{ rows: 6 }} active />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                border: "1px solid #e0e0e0",
                borderRadius: 8,
              }}
            >
              <Segmented
                options={readingOptions}
                value={reading}
                onChange={(value) => setReading(value)}
                style={{ width: "100%" }}
                block
              />
            </div>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={9}>
                <Card size="small" style={{ height: "100%" }}>
                  <Space direction="vertical" style={{ display: "flex" }}>
                    <Segmented
                      options={measurementOptions}
                      value={measurement}
                      onChange={(value) => setMeasurement(value)}
                      block
                    />
                    <HorizontalBarChart
                      data={dataRooms}
                      datasetProps={{
                        label: `${selected.label} (${selected.uom})`,
                      }}
                      onClick={onRoomClick}
                      cardProps={{ style: { height: "100%" } }}
                    />
                  </Space>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={15}>
                <Space direction="vertical" style={{ display: "flex" }}>
                  <MinMaxAverageChart
                    title={
                      `Daily ${selected.label}` +
                      (roomName ? ` - ${roomName}` : "")
                    }
                    dataAverage={dataDaily?.map((item) => ({
                      label: item?.label,
                      value: item?.average,
                    }))}
                    dataMinMax={dataDaily?.map((item) => [
                      item?.min,
                      item?.max,
                    ])}
                    onClick={onDateClick}
                    scales={
                      dataDaily?.length
                        ? {
                            y: {
                              suggestedMin: 0,
                              suggestedMax: Math.max(
                                ...dataDaily?.map((item) =>
                                  parseInt(item.max * 1.05)
                                )
                              ),
                            },
                          }
                        : {}
                    }
                    cardProps={{ bodyStyle: { width: "100%" } }}
                    emptyMessage={
                      !roomId ? "Please select room from chart" : "No data"
                    }
                  />

                  <MinMaxAverageChart
                    title={
                      `Hourly ${selected.label}` +
                      (roomName ? ` - ${roomName}` : "") +
                      (dateKey
                        ? ` on ${dayjs(dateKey).format("D MMM YYYY")}`
                        : "")
                    }
                    dataAverage={rawHourly?.data?.map((row) => ({
                      label: row?.usageHour,
                      value: row?.[`average${reading}`],
                    }))}
                    dataMinMax={rawHourly?.data?.map((row) => [
                      row?.[`min${reading}`],
                      row?.[`max${reading}`],
                    ])}
                    cardProps={{ bodyStyle: { width: "100%" } }}
                    emptyMessage={
                      !dateKey ? "Please select date from chart" : "No data"
                    }
                  />
                </Space>
              </Col>
            </Row>
          </>
        )}
      </Space>
    </List>
  );
};
