import { Form, Input } from "antd";
import RoomSelect from "components/room/RoomSelect";
import React from "react";
import { FORM_RULES } from "../../constants";

const PowerMeterForm = ({ ...formProps }) => {
  const initRoomId = formProps?.initialValues?.roomId;

  return (
    <Form {...formProps}>
      <Form.Item
        name="roomId"
        label="Room"
        rules={[FORM_RULES.required]}
        hidden={initRoomId > 0}
      >
        <RoomSelect />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[FORM_RULES.required]}>
        <Input maxLength={80} />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea maxLength={200} rows={3} />
      </Form.Item>
    </Form>
  );
};

export default React.memo(PowerMeterForm);
