import { useSelect } from "@refinedev/antd";
import { Select } from "antd";
import React from "react";

const PowerMeterSelect = ({ value, onChange, roomId, ...otherProps }) => {
  const { selectProps } = useSelect({
    resource: "power_meters",
    optionLabel: "name",
    optionValue: "id",
    pagination: { mode: "off" },
    filters: [{ field: "roomId", operator: "eq", value: roomId }],
    onSearch: (value) => [{ field: "name", operator: "contains", value }],
    queryOptions: { enabled: roomId > 0 },
  });

  return (
    <Select
      {...selectProps}
      value={value}
      onChange={onChange}
      allowClear
      {...otherProps}
    />
  );
};

export default React.memo(PowerMeterSelect);
