import { AuthPage as AntdAuthPage, useModal } from "@refinedev/antd";
import { Typography } from "antd";
import { ForgotPassword } from "components";
import { ColorModeContext } from "contexts/color-mode";
import { useContext } from "react";

const authWrapperProps = {
  style: {
    background:
      "linear-gradient(90deg, rgba(3,101,180,1) 0%, rgba(0,212,255,1) 100%)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
};

const authContentProps = (mode) => ({
  title: (
    <div
      style={{
        textAlign: "center",
        marginBottom: 0,
        fontSize: 32,
        fontWeight: 700,
        color: mode === "dark" ? "#666AF6" : "#000000",
      }}
    >
      <img
        src={`/images/logo${mode === "dark" ? "_color" : ""}.svg`}
        alt="Ecohive logo"
        style={{ marginRight: 6 }}
        width={52}
        height={52}
      />
      ECOHIVE
    </div>
  ),
});

const renderAuthContent = (content) => {
  return <div style={{ maxWidth: 408, margin: "auto" }}>{content}</div>;
};

export const AuthPage = ({ type, formProps }) => {
  const { mode } = useContext(ColorModeContext);
  const { modalProps, show, close } = useModal();

  return (
    <>
      <AntdAuthPage
        type={type}
        title={false}
        wrapperProps={authWrapperProps}
        contentProps={authContentProps(mode)}
        renderContent={renderAuthContent}
        formProps={formProps}
        registerLink={false}
        forgotPasswordLink={
          <Typography.Link
            style={{ fontSize: 12, marginLeft: "auto" }}
            onClick={() => show()}
          >
            Forgot Password
          </Typography.Link>
        }
      />

      <ForgotPassword {...modalProps} onSuccess={() => close()} />
    </>
  );
};
