export const COLORS = Object.freeze({
  Active: "green",
  Inactive: "default",
  Online: "#87d068",
  Offline: "#9e9e9e",
});

export const FORM_RULES = Object.freeze({
  required: {
    required: true,
    message: "This field is required!",
  },
  email: {
    type: "email",
    message: "The input is not a valid email",
  },
});

export const SWITCH_TYPES = Object.freeze([
  "Power Socket",
  "Light",
  "Air Conditioner",
]);
