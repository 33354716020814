import axios from "axios";
import dayjs from "dayjs";
import { stringify } from "query-string";
import { TOKEN_KEY } from "./authProvider";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (request) => {
    console.log(
      "Request URL",
      request.method,
      JSON.stringify(request.url, null, 2)
    );
    if (request.method !== "get") console.log(JSON.stringify(request.data));

    const { token } = JSON.parse(localStorage.getItem(TOKEN_KEY)) ?? {};
    request.headers["Authorization"] = `Bearer ${token}`;

    return request;
  },
  (error) => {
    const customError = {
      ...error,
      message: error.response?.data,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError = {
      ...error,
      message: error.response?.data,
      statusCode: error.response?.status,
    };

    if (error.response?.status === 401) {
      localStorage.removeItem(TOKEN_KEY);
      window.location.href = "/";
    }

    return Promise.reject(customError);
  }
);

const castDayJsToString = (variables) => {
  console.log(variables, "variables");
  if (!variables || typeof variables !== "object" || Array.isArray(variables))
    return variables;

  const payload = {};
  for (const key in variables) {
    if (dayjs.isDayjs(variables[key])) {
      payload[key] = dayjs(variables[key]).format();
    } else {
      payload[key] = variables[key];
    }
  }

  return payload;
};

const mapOperator = (operator) => {
  switch (operator) {
    case "ne":
    case "gte":
    case "lte":
      return `_${operator}`;
    case "contains":
      return "_like";
    case "eq":
    default:
      return "";
  }
};

const generateSort = (sort) => {
  if (sort && sort.length > 0) {
    const _sort = [];
    const _order = [];

    sort.map((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });

    return { _sort, _order };
  }

  return;
};

const generateFilter = (filters) => {
  const queryFilters = {};
  if (filters) {
    filters.map((filter) => {
      if (filter.operator !== "or") {
        const { field, operator, value } = filter;

        if (field === "q") {
          queryFilters[field] = value;
          return;
        }

        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
    });
  }

  return queryFilters;
};

const JsonServer = (apiUrl, httpClient = axiosInstance) => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

    const { headers: headersFromMeta, method } = meta ?? {};
    const requestMethod = method ?? "get";

    const queryFilters = generateFilter(filters);

    const query = {};

    if (mode === "server") {
      query._start = (current - 1) * pageSize;
      query._end = current * pageSize;
    }

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      query._sort = _sort.join(",");
      query._order = _order.join(",");
    }

    const { data, headers } = await httpClient[requestMethod](
      `${url}?${stringify(query)}&${stringify(queryFilters)}`,
      { headers: headersFromMeta }
    );

    const total = +headers["x-total-count"];

    return {
      data,
      total: total || data.length,
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "get";

    const { data } = await httpClient[requestMethod](
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
      { headers }
    );

    return { data };
  },

  create: async ({ resource, variables, meta }) => {
    const url = `${apiUrl}/${resource}`;

    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "post";

    const { data } = await httpClient[requestMethod](
      url,
      castDayJsToString(variables),
      { headers }
    );

    return { data };
  },

  createMany: async ({ resource, variables, meta }) => {
    const url = `${apiUrl}/${resource}`;

    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "post";

    const response = await Promise.all(
      variables.map(async (params) => {
        const { data } = await httpClient[requestMethod](
          url,
          castDayJsToString(params),
          { headers }
        );

        return data;
      })
    );

    return { data: response };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "put";

    const { data } = await httpClient[requestMethod](
      url,
      castDayJsToString(variables),
      { headers }
    );
    return { data };
  },

  updateMany: async ({ resource, ids, variables, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "patch";

    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient[requestMethod](
          `${apiUrl}/${resource}/${id}`,
          castDayJsToString(variables),
          { headers }
        );

        return data;
      })
    );

    return { data: response };
  },

  getOne: async ({ resource, id, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "get";

    const { data } = await httpClient[requestMethod](url, { headers });

    return { data };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: castDayJsToString(variables),
      headers,
    });

    return { data };
  },

  deleteMany: async ({ resource, ids, variables, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = method ?? "delete";

    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient[requestMethod](
          `${apiUrl}/${resource}/${id}`,
          {
            data: castDayJsToString(variables),
            headers,
          }
        );

        return data;
      })
    );

    return { data: response };
  },

  getApiUrl: () => apiUrl,

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default JsonServer;
