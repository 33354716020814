import { useList } from "@refinedev/core";
import { Button, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";

const BranchFilter = ({ value, onChange }) => {
  const [selectedValues, setSelectedValues] = useState(value ?? []);

  useEffect(() => onChange?.(selectedValues), [selectedValues]);

  const { data, isLoading } = useList({
    resource: "branches",
    pagination: { mode: "off" },
  });

  const toggleButton = (id) => {
    const index = selectedValues.indexOf(id);

    if (index < 0) {
      setSelectedValues((prev) => [...prev, id]);
    } else {
      setSelectedValues((prev) => prev.filter((val) => val !== id));
    }
  };

  if (isLoading) return <Skeleton active paragraph={{ rows: 6 }} />;

  return (
    <Space wrap>
      <Button
        shape="round"
        type={selectedValues.length === 0 ? "primary" : "default"}
        onClick={() => setSelectedValues([])}
      >
        ALL
      </Button>

      {data?.data.map(({ id, name }) => (
        <Button
          key={id}
          shape="round"
          type={selectedValues.includes(id) ? "primary" : "default"}
          onClick={() => toggleButton(id)}
        >
          {name}
        </Button>
      ))}
    </Space>
  );
};

export default BranchFilter;
