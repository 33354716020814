import { AuthPage, ErrorComponent } from "@refinedev/antd";
import { useLink } from "@refinedev/core";
import { Button, Card, Result } from "antd";
import { Link, useSearchParams } from "react-router-dom";

const authWrapperProps = {
  style: {
    background:
      "linear-gradient(90deg, rgba(3,101,180,1) 0%, rgba(0,212,255,1) 100%)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
};

const renderAuthContent = (content) => {
  return (
    <div style={{ maxWidth: 408, margin: "auto" }}>
      <Link to="/">
        <div
          style={{
            textAlign: "center",
            marginBottom: 12,
            fontSize: 32,
            fontWeight: 700,
            color: "#000000",
          }}
        >
          <img
            src={`/images/logo.svg`}
            alt="Ecohive"
            style={{ marginRight: 6 }}
            width={52}
            height={52}
          />
          ECOHIVE
        </div>
      </Link>
      {content}
    </div>
  );
};

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const resettoken = searchParams.get("resettoken");
  const status = searchParams.get("status");
  const Link = useLink();

  // Reset successful
  if (status) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          ...authWrapperProps.style,
        }}
      >
        <Card style={{ maxWidth: 408, margin: "auto" }}>
          <Result
            icon={
              <img
                src="/images/lock-checked.svg"
                alt="reset password success"
                width={200}
                height="auto"
              />
            }
            title="Success!"
            subTitle="Your password has been changed."
            extra={
              <Link to="/">
                <Button type="primary" size="large" style={{ width: "100%" }}>
                  Back to Login
                </Button>
              </Link>
            }
          />
        </Card>
      </div>
    );
  }

  // Reset password page must come with email and reset token
  if (!email || !resettoken) {
    return <ErrorComponent />;
  }

  return (
    <AuthPage
      type="updatePassword"
      wrapperProps={authWrapperProps}
      renderContent={renderAuthContent}
      formProps={{ email, resettoken }}
    />
  );
};
