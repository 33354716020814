import {
  CreateButton,
  List,
  SaveButton,
  TagField,
  getDefaultSortOrder,
  useForm,
  useTable,
} from "@refinedev/antd";
import { Drawer, Form, Space, Table } from "antd";
import BranchForm from "components/branch/BranchForm";
import MoreDropDown from "components/shared/MoreDropDown";
import SearchBox from "components/shared/SearchBox";
import { useState } from "react";
import { COLORS } from "../../constants";

export const BranchList = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const {
    tableProps,
    searchFormProps,
    sorters: sorter,
  } = useTable({
    onSearch: (params) => {
      return Object.keys(params).map((field) => ({
        field,
        operator: "eq",
        value: params[field],
      }));
    },
  });

  const { formProps: formPropsCreate, saveButtonProps: saveButtonPropsCreate } =
    useForm({
      action: "create",
      onMutationSuccess: () => {
        setShowCreate(false);
        formPropsCreate?.form?.resetFields();
      },
    });

  const {
    formProps: formPropsEdit,
    saveButtonProps: saveButtonPropsEdit,
    setId: setEditId,
  } = useForm({
    action: "edit",
    onMutationSuccess: () => {
      setShowEdit(false);
      formPropsEdit?.form?.resetFields();
    },
  });

  return (
    <>
      <Form {...searchFormProps}>
        <Form.Item name="q" hidden noStyle>
          <input type="hidden" />
        </Form.Item>
      </Form>
      <List
        headerButtons={
          <Space>
            <SearchBox
              onSearch={(q) => {
                searchFormProps?.form?.setFieldsValue({ q });
                searchFormProps?.form?.submit();
              }}
            />
            <CreateButton onClick={() => setShowCreate(true)} />
          </Space>
        }
      >
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column
            dataIndex="name"
            title="Name"
            sorter
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
          />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value) => <TagField color={COLORS[value]} value={value} />}
            sorter
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
          />
          <Table.ColumnGroup title="Power Consumption Alert">
            <Table.Column
              dataIndex="powerConsumptionAlertMin"
              title="Min"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder(
                "powerConsumptionAlertMin",
                sorter
              )}
            />
            <Table.Column
              dataIndex="powerConsumptionAlertMax"
              title="Max"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder(
                "powerConsumptionAlertMax",
                sorter
              )}
            />
          </Table.ColumnGroup>
          <Table.ColumnGroup title={`Temperature (\u00b0C) Alert`}>
            <Table.Column
              dataIndex="temperatureAlertMin"
              title="Min"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder(
                "temperatureAlertMin",
                sorter
              )}
            />
            <Table.Column
              dataIndex="temperatureAlertMax"
              title="Max"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder(
                "temperatureAlertMax",
                sorter
              )}
            />
          </Table.ColumnGroup>
          <Table.ColumnGroup title="Humidity Alert">
            <Table.Column
              dataIndex="humidityAlertMin"
              title="Min"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder("humidityAlertMin", sorter)}
            />
            <Table.Column
              dataIndex="humidityAlertMax"
              title="Max"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder("humidityAlertMax", sorter)}
            />
          </Table.ColumnGroup>
          <Table.ColumnGroup title="CO2 Alert">
            <Table.Column
              dataIndex="co2AlertMin"
              title="Min"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder("co2AlertMin", sorter)}
            />
            <Table.Column
              dataIndex="co2AlertMax"
              title="Max"
              align="center"
              width={110}
              sorter
              defaultSortOrder={getDefaultSortOrder("co2AlertMax", sorter)}
            />
          </Table.ColumnGroup>
          <Table.Column
            key="actions"
            fixed="right"
            width={10}
            render={(_, record) => (
              <MoreDropDown
                resource="branches"
                id={record?.id}
                viewButtonProps={{ hidden: true }}
                editButtonProps={{
                  onClick: () => {
                    setEditId(record?.id);
                    setShowEdit(true);
                  },
                }}
              />
            )}
          />
        </Table>
      </List>

      <Drawer
        title="Create Branch"
        open={showCreate}
        onClose={() => setShowCreate(false)}
        extra={[<SaveButton {...saveButtonPropsCreate} />]}
      >
        <BranchForm {...formPropsCreate} layout="vertical" />
      </Drawer>

      <Drawer
        title="Edit Branch"
        open={showEdit}
        onClose={() => setShowEdit(false)}
        extra={[<SaveButton {...saveButtonPropsEdit} />]}
      >
        <BranchForm {...formPropsEdit} layout="vertical" />
      </Drawer>
    </>
  );
};
