import { Input } from "antd";
import React from "react";

const SearchBox = ({ onSearch = () => {}, inputStyle = {}, ...otherProps }) => {
  return (
    <Input.Search
      style={{ width: 230, ...inputStyle }}
      placeholder="Search..."
      onSearch={onSearch}
      allowClear
      {...otherProps}
    />
  );
};

export default React.memo(SearchBox);
