import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  BankOutlined,
  BarChartOutlined,
  DashboardOutlined,
  HomeOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  ErrorComponent,
  ThemedLayoutV2,
  notificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { AuthPage } from "pages/auth";
import { ResetPassword } from "pages/auth/reset-password";
import { BranchList } from "pages/branches";
import { DashboardPage } from "pages/dashboard";
import { PowerMeterReadingList, RoomReadingList } from "pages/readings";
import {
  ActiveEnergyAnalysisReport,
  RoomReadingAnalysisReport,
} from "pages/reports";
import { RoomList, RoomShow } from "pages/rooms";
import { SettingShow } from "pages/settings";
import { UserList } from "pages/users";
import UserCreate from "pages/users/create";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { Title } from "./components/title";
import { ColorModeContextProvider } from "./contexts/color-mode";
import dataProvider from "./dataProvider";
// import ability from "./permissions";

function App() {
  const API_URL = "https://vivace.servebbs.com/remmsapi";

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            authProvider={authProvider(API_URL)}
            dataProvider={dataProvider(API_URL)}
            // accessControlProvider={{
            //   can: async ({ resource, action, params }) => {
            //     // const { role } = JSON.parse(localStorage.getItem(TOKEN_KEY));
            //     // const roleName = role?.[0]?.replace(/ /g, "_").toLowerCase();

            //     // Convert "list" & "show" to become "read" action
            //     const crud = ["list", "show"].includes(action)
            //       ? "read"
            //       : action;

            //     return Promise.resolve({
            //       can: ability.can(crud, resource),
            //     });
            //   },
            // }}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Dashboard",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: "branches",
                list: "/branches",
                // create: "/branches/create",
                // edit: "/branches/edit/:id",
                // show: "/branches/show/:id",
                meta: {
                  canDelete: true,
                  icon: <BankOutlined />,
                },
              },
              {
                name: "rooms",
                list: "/rooms",
                show: "/rooms/show/:id",
                meta: {
                  canDelete: true,
                  icon: <HomeOutlined />,
                },
              },
              {
                name: "readings",
                list: "/",
                meta: {
                  icon: <TableOutlined />,
                },
              },
              {
                name: "room_readings",
                list: "/readings/room",
                meta: {
                  label: "Rooms",
                  parent: "readings",
                },
              },
              {
                name: "power_meter_readings",
                list: "/readings/power-meter",
                meta: {
                  label: "Power Meters",
                  parent: "readings",
                },
              },
              {
                name: "reports",
                list: "/",
                meta: {
                  icon: <BarChartOutlined />,
                },
              },
              {
                name: "active-energy-analysis",
                list: "/reports/active-energy-analysis",
                meta: {
                  label: "Active Energy",
                  parent: "reports",
                },
              },
              {
                name: "room-reading-analysis",
                list: "/reports/room-reading-analysis",
                meta: {
                  label: "Room Reading",
                  parent: "reports",
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2 Header={Header} Title={Title}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />
                <Route path="/branches">
                  <Route index element={<BranchList />} />
                  {/* <Route path="create" element={<BranchCreate />} /> */}
                  {/* <Route path="edit/:id" element={<BranchEdit />} /> */}
                  {/* <Route path="show/:id" element={<BranchShow />} /> */}
                </Route>
                <Route path="/rooms">
                  <Route index element={<RoomList />} />
                  <Route path="show/:id" element={<RoomShow />} />
                </Route>
                <Route path="/readings">
                  <Route path="room" element={<RoomReadingList />} />
                  <Route
                    path="power-meter"
                    element={<PowerMeterReadingList />}
                  />
                </Route>
                <Route path="/reports">
                  <Route
                    path="active-energy-analysis"
                    element={<ActiveEnergyAnalysisReport />}
                  />
                  <Route
                    path="room-reading-analysis"
                    element={<RoomReadingAnalysisReport />}
                  />
                </Route>
                <Route path="/users">
                  <Route index element={<UserList />} />
                  <Route path="create" element={<UserCreate />} />
                </Route>
                <Route path="/settings">
                  <Route index element={<SettingShow />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<AuthPage type="login" />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
