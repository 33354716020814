import { FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Space } from "antd";
import BranchSelect from "components/branch/BranchSelect";
import PowerMeterSelect from "components/powerMeter/PowerMeterSelect";
import RoomSelect from "components/room/RoomSelect";
import dayjs from "dayjs";
import React from "react";
import { FORM_RULES } from "../../constants";

const ReadingFilter = ({ formProps, hidePowerMeter = false }) => {
  const branchId = Form.useWatch("branchId", formProps?.form);
  const roomId = Form.useWatch("roomId", formProps?.form);

  return (
    <Form layout="vertical" {...formProps}>
      <Space wrap>
        <Form.Item
          name="readingDate"
          label="Reading Date"
          rules={[FORM_RULES.required]}
        >
          <DatePicker.RangePicker
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </Form.Item>
        <Form.Item name="branchId" label="Branch">
          <BranchSelect
            style={{ width: 200 }}
            placeholder="All Branches"
            onChange={() => formProps?.form?.resetFields(["roomId"])}
          />
        </Form.Item>
        <Form.Item name="roomId" label="Room">
          <RoomSelect
            style={{ width: 200 }}
            placeholder="All Rooms"
            branchId={branchId}
            disabled={!branchId}
            onChange={() => {
              if (!hidePowerMeter)
                formProps?.form?.resetFields(["powerMeterId"]);
            }}
          />
        </Form.Item>
        {!hidePowerMeter && (
          <Form.Item name="powerMeterId" label="Power Meter">
            <PowerMeterSelect
              style={{ width: 200 }}
              placeholder="All Power Meters"
              roomId={roomId}
              disabled={!roomId}
            />
          </Form.Item>
        )}
        <Form.Item label=" ">
          <Button icon={<FilterOutlined />} type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default React.memo(ReadingFilter);
