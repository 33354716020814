import axios from "axios";
export const TOKEN_KEY = "remms-auth";

export const authProvider = (apiUrl) => ({
  login: async ({ email, password, remember }) => {
    if (email && password) {
      try {
        const url = `${apiUrl}/authentication/login`;
        const response = await axios.post(url, { username: email, password });

        localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
        return { success: true, redirectTo: "/" };
      } catch (error) {
        return {
          success: false,
          error: { message: "Login failed", name: error.response?.data },
        };
      }
    }

    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Email and Password are required!",
      },
    };
  },

  logout: async () => {
    try {
      const { token } = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? "{}");

      if (token) {
        const url = `${apiUrl}/authentication/logout`;
        const config = { headers: { token } };

        await axios.post(url, {}, config);
      }
    } catch (error) {
      console.log("logout", error.response?.data);
    } finally {
      localStorage.removeItem(TOKEN_KEY);
      return { success: true, redirectTo: "/" };
    }
  },

  onError: async (error) => {
    console.error(error);
    return { error };
  },

  check: () => {
    const { token } = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? "{}");

    if (token) {
      return { authenticated: true };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
      error: {
        message: "Check failed",
        name: "Not authenticated",
      },
    };
  },

  getPermissions: async () => {
    const { role = [] } = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? "{}");
    return role;
  },

  getIdentity: async () => {
    const { token } = JSON.parse(localStorage.getItem(TOKEN_KEY)) ?? {};

    if (token) {
      try {
        const url = `${apiUrl}/authentication/profile`;
        const config = { headers: { token } };

        const response = await axios.get(url, config);

        return response.data;
      } catch (error) {
        return {
          success: false,
          error: {
            message: "Unable to get user identity",
            name: error.response?.data,
          },
        };
      }
    }

    return {
      success: false,
      error: {
        message: "Unable to get user identity",
        name: "You are not logging in!",
      },
    };
  },

  forgotPassword: async ({ email }) => {
    if (email) {
      try {
        const url = `${apiUrl}/authentication/forgotpassword`;
        const response = await axios.post(url, { email });

        const { success, message } = response?.data ?? {};
        if (success) {
          return {
            success: true,
            redirectTo: false,
            message,
          };
        } else {
          return {
            success: false,
            error: {
              message: "Forgot password failed",
              name: message,
            },
          };
        }
      } catch (error) {
        return {
          success: false,
          error: {
            message: "Forgot password failed",
            name: error.response?.data,
          },
        };
      }
    }

    return {
      success: false,
      error: {
        message: "Forgot password failed",
        name: "Email is required!",
      },
    };
  },

  updatePassword: async ({ email, password, resettoken }) => {
    if (email && password && resettoken) {
      try {
        const url = `${apiUrl}/authentication/resetpassword`;
        await axios.post(url, { email, password, resettoken });

        // Success
        return { success: true, redirectTo: "/reset-password?status=1" };
      } catch (error) {
        return {
          success: false,
          error: {
            message: "Update password failed",
            name: error.response?.data,
          },
        };
      }
    }

    return {
      success: false,
      error: {
        message: "Update password failed",
        name: "Invalid URL. Please contact system administrator",
      },
    };
  },
});
