import { useGetIdentity } from "@refinedev/core";
import { Layout as AntdLayout, Space, Switch, Typography, theme } from "antd";
import { Notification } from "components/notification";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useContext } from "react";
import { ColorModeContext } from "../../contexts/color-mode";
dayjs.extend(relativeTime);

const { useToken } = theme;

type IUser = {
  id: number;
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationLogo: string;
};

export const Header: React.FC = () => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<IUser>();
  const { mode, setMode } = useContext(ColorModeContext);

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
      }}
    >
      <Space size={12} align="center">
        <Notification />

        <Space
          direction="vertical"
          style={{ display: "flex", lineHeight: 1 }}
          size="small"
          align="end"
        >
          {user?.firstName && (
            <Typography.Text strong>{user?.firstName}</Typography.Text>
          )}
          <Switch
            size="small"
            checkedChildren="🌛"
            unCheckedChildren="🔆"
            onChange={() => setMode(mode === "light" ? "dark" : "light")}
            defaultChecked={mode === "dark"}
          />
        </Space>

        {user?.organizationLogo && (
          <img
            src={user?.organizationLogo}
            alt={user?.organizationName ?? "Organization logo"}
            style={{ maxHeight: 56, width: "auto" }}
          />
        )}
      </Space>
    </AntdLayout.Header>
  );
};
