import { Card, Empty, Space, Typography } from "antd";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const AreaChart = ({
  title,
  data = [],
  datasetProps = {},
  cardProps = {},
  maintainAspectRatio = false,
  scales = {},
  onClick,
  emptyMessage,
}) => {
  const chartData = {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        fill: true,
        label: title,
        data: data.map(({ value }) => value),
        borderColor: "rgb(102, 106, 246)",
        backgroundColor: "rgba(102, 106, 246, 0.7)",
        ...datasetProps,
      },
    ],
  };

  return (
    <Card size="small" {...cardProps}>
      <Space direction="vertical" size={8} style={{ display: "flex" }}>
        {title && <Typography.Title level={5}>{title}</Typography.Title>}
        {data.length ? (
          <Line
            options={{
              ...options,
              maintainAspectRatio,
              onClick: (e, element) => onClick?.(element),
              scales,
            }}
            data={chartData}
          />
        ) : (
          <Empty description={emptyMessage} />
        )}
      </Space>
    </Card>
  );
};

export default React.memo(AreaChart);
