import {
  CreateButton,
  List,
  SaveButton,
  TagField,
  getDefaultSortOrder,
  useForm,
  useTable,
} from "@refinedev/antd";
import { Col, Drawer, Form, Row, Space, Table, Typography } from "antd";
import BranchFilter from "components/branch/BranchFilter";
import RoomForm from "components/room/RoomForm";
import MoreDropDown from "components/shared/MoreDropDown";
import SearchBox from "components/shared/SearchBox";
import { useState } from "react";
import { COLORS } from "../../constants";

export const RoomList = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const {
    tableProps,
    searchFormProps,
    sorters: sorter,
  } = useTable({
    resource: "rooms",
    onSearch: (params) => {
      return Object.keys(params).map((field) => ({
        field,
        operator: "eq",
        value: params[field],
      }));
    },
  });

  const { formProps: formPropsCreate, saveButtonProps: saveButtonPropsCreate } =
    useForm({
      action: "create",
      onMutationSuccess: () => {
        formPropsCreate?.form?.resetFields();
        setShowCreate(false);
      },
    });

  const {
    formProps: formPropsEdit,
    saveButtonProps: saveButtonPropsEdit,
    setId: setEditId,
  } = useForm({
    action: "edit",
    onMutationSuccess: () => setShowEdit(false),
  });

  return (
    <>
      <Form
        id="searchForm"
        {...searchFormProps}
        onValuesChange={() => searchFormProps?.form?.submit()}
      >
        <Form.Item name="q" hidden noStyle>
          <input type="hidden" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={7}>
            <Space
              direction="vertical"
              align="start"
              style={{ display: "flex", marginTop: 8, marginBottom: 40 }}
            >
              <Typography.Title level={5}>Branches</Typography.Title>
              <Form.Item name="branchId" noStyle>
                <BranchFilter />
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={17}>
            <List
              headerButtons={() => (
                <Space>
                  <SearchBox
                    onSearch={(q) => {
                      searchFormProps?.form?.setFieldsValue({ q });
                      searchFormProps?.form?.submit();
                    }}
                  />
                  <CreateButton onClick={() => setShowCreate(true)} />
                </Space>
              )}
            >
              <Table {...tableProps} rowKey="id">
                <Table.Column
                  dataIndex="branchName"
                  title="Branch"
                  sorter
                  defaultSortOrder={getDefaultSortOrder("branchName", sorter)}
                />
                <Table.Column
                  dataIndex="name"
                  title="Name"
                  sorter
                  defaultSortOrder={getDefaultSortOrder("name", sorter)}
                />
                <Table.Column
                  dataIndex="description"
                  title="Description"
                  sorter
                  defaultSortOrder={getDefaultSortOrder("description", sorter)}
                />
                <Table.Column
                  dataIndex="autoMode"
                  title="Auto Mode"
                  render={(value) => {
                    if (value == null) return "";
                    return (
                      <TagField
                        color={value ? "blue" : "default"}
                        value={value ? "On" : "Off"}
                      />
                    );
                  }}
                  sorter
                  defaultSortOrder={getDefaultSortOrder("autoMode", sorter)}
                />
                <Table.Column
                  dataIndex="status"
                  title="Status"
                  render={(value) => {
                    if (value == null) return "";
                    return <TagField color={COLORS[value]} value={value} />;
                  }}
                  sorter
                  defaultSortOrder={getDefaultSortOrder("status", sorter)}
                />
                <Table.Column
                  key="actions"
                  fixed="right"
                  render={(_, record) => (
                    <MoreDropDown
                      resource="rooms"
                      id={record?.id}
                      viewButtonProps={{ label: "Manage" }}
                      editButtonProps={{
                        onClick: () => {
                          setEditId(record?.id);
                          setShowEdit(true);
                        },
                      }}
                    />
                  )}
                />
              </Table>
            </List>
          </Col>
        </Row>
      </Form>

      <Drawer
        title="Create Room"
        open={showCreate}
        onClose={() => setShowCreate(false)}
        extra={[<SaveButton key="create" {...saveButtonPropsCreate} />]}
      >
        <RoomForm
          {...formPropsCreate}
          initialValues={{ autoMode: true }}
          layout="vertical"
        />
      </Drawer>

      <Drawer
        title="Edit Room"
        open={showEdit}
        onClose={() => setShowEdit(false)}
        extra={[<SaveButton key="edit" {...saveButtonPropsEdit} />]}
      >
        <RoomForm {...formPropsEdit} layout="vertical" />
      </Drawer>
    </>
  );
};
