import { Card, Empty, Space, Typography } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: { borderWidth: 2 },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

const HorizontalBarChart = ({
  title,
  data = [],
  datasetProps = {},
  cardProps = {},
  maintainAspectRatio = false,
  scales = {},
  onClick,
  emptyMessage,
}) => {
  const chartData = {
    labels: data?.map(({ label }) => label),
    datasets: [
      {
        label: "Dataset 1",
        data: data?.map(({ value }) => value),
        borderColor: "rgb(102, 106, 246)",
        backgroundColor: "rgba(102, 106, 246, 0.7)",
        ...datasetProps,
      },
    ],
  };

  return (
    <Card size="small" {...cardProps}>
      <Space direction="vertical" size={8} style={{ display: "flex" }}>
        {title && <Typography.Title level={5}>{title}</Typography.Title>}
        {data.length ? (
          <Bar
            options={{
              ...options,
              maintainAspectRatio,
              onClick: (e, element) => onClick?.(element),
              scales,
            }}
            data={chartData}
          />
        ) : (
          <Empty description={emptyMessage} />
        )}
      </Space>
    </Card>
  );
};

export default React.memo(HorizontalBarChart);
