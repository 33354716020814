import { Form, Input, Select, Switch } from "antd";
import BranchSelect from "components/branch/BranchSelect";
import React from "react";
import { FORM_RULES } from "../../constants";

const RoomForm = ({ ...formProps }) => {
  return (
    <Form {...formProps}>
      <Form.Item name="branchId" label="Branch" rules={[FORM_RULES.required]}>
        <BranchSelect />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[FORM_RULES.required]}>
        <Input maxLength={80} />
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[FORM_RULES.required]}>
        <Select
          options={["Active", "Inactive"].map((value) => ({
            label: value,
            value,
          }))}
        />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={3} maxLength={200} />
      </Form.Item>
      <Form.Item name="autoMode" label="Auto Mode" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default React.memo(RoomForm);
