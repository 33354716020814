import { Create, getValueFromEvent, useForm } from "@refinedev/antd";
import { file2Base64 } from "@refinedev/core";
import { Form, Input, Upload } from "antd";
import { FORM_RULES } from "../../constants";
import { useState } from "react";

const UserCreate = () => {
  const { formProps, saveButtonProps } = useForm({ action: "create" });
  const [base64Data, setBase64Data] = useState("");

  return (
    <Create saveButtonProps={saveButtonProps}>
      <div>{base64Data}</div>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={async (values) => {
          const base64Files = [];
          // @ts-ignore
          const { organizationLogo } = values;

          for (const file of organizationLogo) {
            if (file.originFileObj) {
              const base64String = await file2Base64(file);

              base64Files.push({
                ...file,
                base64String,
              });
            } else {
              base64Files.push(file);
            }
          }
          setBase64Data(base64Files?.[0]?.base64String);
          console.log({ ...values, organizationLogo: base64Files });
          //   return (
          //     formProps.onFinish &&
          //     formProps.onFinish({
          //       ...values,
          //       avatar: base64Files,
          //     })
          //   );
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[FORM_RULES.required, FORM_RULES.email]}
        >
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[FORM_RULES.required]}
        >
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[FORM_RULES.required]}
        >
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[FORM_RULES.required]}
        >
          <Input maxLength={30} />
        </Form.Item>
        <Form.Item name="organizationName" label="Organization Name">
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item label="Organization Logo">
          <Form.Item
            name="organizationLogo"
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              listType="picture"
              maxCount={1}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};

export default UserCreate;
