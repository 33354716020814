import { useForgotPassword, useNotification } from "@refinedev/core";
import { Form, Input, Modal, Space, Typography } from "antd";

type ForgotPasswordProps = {
  onSuccess: () => void;
  [key: string]: unknown;
};

type forgotPasswordVariables = {
  email: string;
};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  onSuccess,
  ...modalProps
}) => {
  const [form] = Form.useForm();
  const { open } = useNotification();

  const { mutateAsync: forgotPasswordAsync, isLoading } =
    useForgotPassword<forgotPasswordVariables>();

  const onFinish = (values: forgotPasswordVariables) => {
    forgotPasswordAsync(values).then(({ message }) => {
      open?.({
        type: "success",
        message: message as string,
        description: "Success!",
      });
      onSuccess?.();
    });
  };

  return (
    <Modal
      {...modalProps}
      okText="Send"
      okButtonProps={{ loading: isLoading }}
      onOk={() => form.submit()}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/forgot-password.png"
            style={{ width: "60%", height: "auto", objectFit: "cover" }}
          />
          <Typography.Title level={5}>Forgot Password?</Typography.Title>
          <Typography.Text
            type="secondary"
            style={{ paddingBottom: 24, textAlign: "center" }}
          >
            Enter your email and we'll send you a link to get back into your
            account.
          </Typography.Text>
        </div>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "The input is not a valid email",
              },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
