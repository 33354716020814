import { useSelect } from "@refinedev/antd";
import { Select } from "antd";
import React, { useEffect, useState } from "react";

const RoomSelect = ({ value, onChange, branchId, ...otherProps }) => {
  const [bid, setBid] = useState(branchId);
  const { selectProps, queryResult } = useSelect({
    resource: "rooms",
    pagination: { mode: "off" },
    filters: !!bid ? [{ field: "branchId", operator: "eq", value: bid }] : [],
    sorters: [{ field: "branchId", order: "asc" }],
    onSearch: (value) => [
      { field: "name", operator: "contains", value },
      { field: "branchName", operator: "contains", value },
    ],
  });

  useEffect(() => setBid(branchId), [branchId]);

  // Group Options by Branch
  const objOptions =
    queryResult?.data?.data?.reduce((acc, cur) => {
      const key = cur?.branchName;
      acc[key] = acc[key] || [];
      acc[key].push({ value: cur?.id, label: cur?.name });
      return acc;
    }, {}) ?? {};

  return (
    <Select
      {...selectProps}
      options={Object.keys(objOptions).map((key) => ({
        label: key,
        options: objOptions[key],
      }))}
      allowClear
      value={value}
      onChange={(newValue) => onChange?.(newValue)}
      {...otherProps}
    />
  );
};

export default React.memo(RoomSelect);
