import { BellOutlined } from "@ant-design/icons";
import { useSimpleList } from "@refinedev/antd";
import { HttpError, useUpdateMany } from "@refinedev/core";
import {
  List as AntdList,
  Badge,
  Button,
  Drawer,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState } from "react";

dayjs.extend(relativeTime);

type INotification = {
  id: number;
  branchName: string;
  roomName: string;
  status: string;
  message: string;
  createdAt: string;
};

export const Notification: React.FC<{}> = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [unreadIds, setUnreadIds] = useState<number[]>([]);
  const [openTimeStamp, setOpenTimestamp] = useState<number>(dayjs().unix());

  const { listProps, queryResult } = useSimpleList<INotification, HttpError>({
    resource: "notifications",
    sorters: { initial: [{ field: "createdAt", order: "desc" }] },
    queryOptions: {
      onSuccess(data) {
        const ids =
          data?.data
            ?.filter(({ status }) => status === "Unread")
            .map(({ id }) => id) ?? [];
        setUnreadIds(ids);
      },
    },
  });

  const { refetch } = queryResult ?? {};

  // Check notification every 30 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      refetch?.();
    }, 30 * 1000);

    return () => clearInterval(timer);
  }, []);

  const { mutate: mutateUpdateMany } = useUpdateMany();

  const timeFromNow = (dateString: string) => {
    const target = dayjs(dateString);
    if (dayjs().diff(target, "minute") > 1439) {
      return target.format("D MMM\nh:m A");
    } else {
      return target.fromNow();
    }
  };

  return (
    <>
      <Space>
        <Badge count={unreadIds.length} offset={[0, 10]}>
          <Button
            style={{ marginTop: 6 }}
            type="text"
            icon={
              <BellOutlined twoToneColor="#1677ff" style={{ fontSize: 18 }} />
            }
            onClick={() => {
              setShowAlert(true);
              setOpenTimestamp(dayjs().unix());
            }}
          />
        </Badge>
      </Space>
      <Drawer
        title="Alerts"
        open={showAlert}
        onClose={() => {
          setShowAlert(false);

          // Set notifications status as read when drawer open more than 2 secs
          if (dayjs().unix() - openTimeStamp >= 2 && unreadIds.length) {
            mutateUpdateMany({
              resource: "notifications",
              ids: unreadIds,
              values: { status: "Read" },
              successNotification: false,
              errorNotification: false,
            });
          }
        }}
      >
        <AntdList
          {...listProps}
          rowKey="id"
          renderItem={(item) => {
            const isUnread = item?.status === "Unread";
            const itemStyle = isUnread
              ? { background: "radial-gradient(#e1f5fe, #f2f2fd, #ffffff)" }
              : {};

            return (
              <AntdList.Item
                style={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  marginLeft: -4,
                  marginRight: -4,
                  ...itemStyle,
                }}
                extra={[
                  <Typography.Text
                    key="createdAt"
                    type="secondary"
                    style={{ fontSize: 12, width: 50, textAlign: "end" }}
                  >
                    {timeFromNow(item?.createdAt)}
                  </Typography.Text>,
                ]}
              >
                <AntdList.Item.Meta
                  title={
                    <div
                      className="ant-list-item-meta-title"
                      style={{ fontWeight: isUnread ? 700 : 400 }}
                    >
                      {`${item?.branchName ?? ""} - ${item?.roomName}`}
                    </div>
                  }
                  description={item?.message}
                />
              </AntdList.Item>
            );
          }}
        />
      </Drawer>
    </>
  );
};
