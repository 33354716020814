import { DateField, ExportButton, List, useTable } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { Modal, Space, Table } from "antd";
import ReadingFilter from "components/reading/ReadingFilter";
import dayjs from "dayjs";
import { useState } from "react";
import * as XLSX from "xlsx";

const { confirm } = Modal;

export const PowerMeterReadingList = () => {
  const [exportNow, setExportNow] = useState(false);
  const [filters, setFilters] = useState([]);

  const { tableProps, searchFormProps } = useTable({
    resource: "power_meter_readings",
    sorters: { initial: [{ field: "readingDate", order: "desc" }] },
    onSearch: ({ readingDate, ...rest }) => {
      const filters = Object.keys(rest).map((field) => ({
        field,
        operator: "eq",
        value: rest[field],
      }));

      if (readingDate?.[0]) {
        filters.push({
          field: "readingDate",
          operator: "gte",
          value: dayjs(readingDate[0]).format("YYYY-MM-DD"),
        });
      }

      if (readingDate?.[1]) {
        filters.push({
          field: "readingDate",
          operator: "lte",
          value: dayjs(readingDate[1]).format("YYYY-MM-DD"),
        });
      }

      setFilters(filters);
      return filters;
    },
  });

  useList({
    resource: "power_meter_readings",
    filters,
    sorters: [{ field: "readingDate", order: "desc" }],
    pagination: { mode: "off" },
    queryOptions: {
      enabled: exportNow,
      onSuccess: ({ data }) => {
        const mappedData = data?.map((item) => {
          const keys = Object.keys(item);
          return keys.reduce((acc, cur) => {
            const result = cur.replace(/([A-Z])/g, " $1");
            const name =
              result[0].toUpperCase() + result.substring(1).toLowerCase();

            acc[name] = item[cur];
            return acc;
          }, {});
        });

        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Power-Meter-Readings.xlsx");
      },
      onSettled: () => setExportNow(false),
    },
  });

  return (
    <List
      title="Power Meter Readings"
      headerButtons={() => (
        <ExportButton
          loading={exportNow}
          onClick={() =>
            confirm({
              content: "Are you sure want to export data now?",
              okText: "Export",
              onOk: () => setExportNow(true),
            })
          }
        />
      )}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
        <ReadingFilter formProps={searchFormProps} />
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="branchName" title="Branch" />
          <Table.Column dataIndex="roomName" title="Room" />
          <Table.Column dataIndex="powerMeterName" title="Power Meter" />
          <Table.Column
            dataIndex="readingDate"
            title="Date Time"
            render={(value) => (
              <DateField format="DD/MM/YYYY h:mm:ss a" value={value} />
            )}
          />
          <Table.Column dataIndex="voltage" title="Voltage" align="right" />
          <Table.Column dataIndex="current" title="Current" align="right" />
          <Table.Column
            dataIndex="powerFactor"
            title="Power Factor"
            align="right"
          />
          <Table.Column
            dataIndex="activePower"
            title="Active Power"
            align="right"
          />
          <Table.Column
            dataIndex="totalActiveEnergy"
            title="Total Active Energy"
            align="right"
          />
        </Table>
      </Space>
    </List>
  );
};
