import { Card, Empty, Space, Typography } from "antd";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const MinMaxAverageChart = ({
  title,
  dataAverage = [],
  dataMinMax = [],
  cardProps = {},
  maintainAspectRatio = false,
  scales = {},
  onClick,
  emptyMessage,
}) => {
  const chartData = {
    labels: dataAverage?.map(({ label }) => label),
    datasets: [
      {
        type: "bar",
        label: "Lowest & Highest",
        data: dataMinMax,
        borderColor: "rgb(102, 106, 246)",
        backgroundColor: "rgba(102, 106, 246, 0.7)",
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        type: "line",
        label: "Average",
        data: dataAverage?.map(({ value }) => value),
        borderColor: "rgb(219, 68, 55)",
        backgroundColor: "rgba(219, 68, 55, 1)",
        borderSkipped: false,
      },
    ],
  };

  return (
    <Card size="small" {...cardProps}>
      <Space direction="vertical" size={8} style={{ display: "flex" }}>
        {title && <Typography.Title level={5}>{title}</Typography.Title>}
        {dataAverage.length ? (
          <Chart
            type="bar"
            options={{
              ...options,
              maintainAspectRatio,
              onClick: (e, element) => onClick?.(element),
              scales,
            }}
            data={chartData}
          />
        ) : (
          <Empty description={emptyMessage} />
        )}
      </Space>
    </Card>
  );
};

export default React.memo(MinMaxAverageChart);
