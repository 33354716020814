import { useLink } from "@refinedev/core";
import { Space, Typography } from "antd";
import { ColorModeContext } from "contexts/color-mode";
import { useContext } from "react";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { mode } = useContext(ColorModeContext);
  const Link = useLink();

  const logo = (
    <img
      src={`/images/logo${mode === "dark" ? "_color" : ""}.svg`}
      alt="logo"
      height={38}
      width={38}
    />
  );

  return (
    <div
      style={{
        height: 72,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link to="/">
        {collapsed ? (
          logo
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {logo}
            <Space
              direction="vertical"
              size={0}
              style={{ display: "flex", marginLeft: 4 }}
            >
              <Typography.Title
                level={4}
                style={{ lineHeight: 1, marginBottom: 0, color: "#46abe2" }}
              >
                REMMS
              </Typography.Title>
              <Typography.Title
                level={5}
                style={{
                  lineHeight: 1,
                  marginBottom: 0,
                  color: mode === "dark" ? "#666AF6" : "#000000",
                }}
              >
                ECOHIVE
              </Typography.Title>
              {/* <div
                style={{
                  fontSize: 22,
                  fontWeight: 700,
                  lineHeight: 1,
                  marginLeft: 6,
                  
                }}
              >
                ECOHIVE
              </div> */}
            </Space>
          </div>
        )}
      </Link>
    </div>
  );
};
