import { Divider, Form, Input, InputNumber, Select, Space } from "antd";
import React from "react";
import { FORM_RULES } from "../../constants";

// FORM Validation Rule: Make sure Max is greater or equal to Min
const maxValidation =
  (minFieldName) =>
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (value == null || getFieldValue(minFieldName) <= value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Invalid range!"));
    },
  });

const BranchForm = ({ ...formProps }) => {
  return (
    <Form {...formProps}>
      <Form.Item name="name" label="Name" rules={[FORM_RULES.required]}>
        <Input maxLength={80} />
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[FORM_RULES.required]}>
        <Select
          options={["Active", "Inactive"].map((value) => ({
            label: value,
            value,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="temperatureAdjustment"
        label="Temperature Adjustment"
        rules={[FORM_RULES.required]}
      >
        <InputNumber addonAfter={`\u00b0C`} style={{ width: 145 }} />
      </Form.Item>

      <Divider orientation="center" plain>
        Out of Range Alerts
      </Divider>

      <Form.Item label="Power Consumption" style={{ margin: 0 }} required>
        <Space align="center">
          <Form.Item
            name="powerConsumptionAlertMin"
            rules={[FORM_RULES.required]}
          >
            <InputNumber placeholder="Min" />
          </Form.Item>
          <div style={{ marginBottom: 24 }}>{` to `}</div>
          <Form.Item
            name="powerConsumptionAlertMax"
            dependencies={["powerConsumptionAlertMin"]}
            rules={[
              FORM_RULES.required,
              maxValidation("powerConsumptionAlertMin"),
            ]}
          >
            <InputNumber placeholder="Max" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item label={`Temperature (\u00b0C)`} style={{ margin: 0 }} required>
        <Space>
          <Form.Item name="temperatureAlertMin" rules={[FORM_RULES.required]}>
            <InputNumber placeholder="Min" />
          </Form.Item>
          <div style={{ marginBottom: 24 }}>{` to `}</div>
          <Form.Item
            name="temperatureAlertMax"
            dependencies={["temperatureAlertMin"]}
            rules={[FORM_RULES.required, maxValidation("temperatureAlertMin")]}
          >
            <InputNumber placeholder="Max" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item label="Humidity" style={{ margin: 0 }} required>
        <Space>
          <Form.Item name="humidityAlertMin" rules={[FORM_RULES.required]}>
            <InputNumber placeholder="Min" />
          </Form.Item>
          <div style={{ marginBottom: 24 }}>{` to `}</div>
          <Form.Item
            name="humidityAlertMax"
            dependencies={["humidityAlertMin"]}
            rules={[FORM_RULES.required, maxValidation("humidityAlertMin")]}
          >
            <InputNumber placeholder="Max" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item label="CO2" style={{ margin: 0 }} required>
        <Space>
          <Form.Item name="co2AlertMin" rules={[FORM_RULES.required]}>
            <InputNumber placeholder="Min" />
          </Form.Item>
          <div style={{ marginBottom: 24 }}>{` to `}</div>
          <Form.Item
            name="co2AlertMax"
            dependencies={["co2AlertMin"]}
            rules={[FORM_RULES.required, maxValidation("co2AlertMin")]}
          >
            <InputNumber placeholder="Max" />
          </Form.Item>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default React.memo(BranchForm);
