import { Card, Typography } from "antd";
import MoreDropDown from "components/shared/MoreDropDown";
import React from "react";

const PowerMeterItem = ({
  id,
  name,
  description,
  viewButtonProps = {},
  editButtonProps = {},
  deleteButtonProps = {},
}) => {
  return (
    <Card
      style={{ width: "100%" }}
      cover={
        <div>
          <MoreDropDown
            resource="power_meters"
            id={id}
            iconProps={{
              style: {
                padding: 8,
                position: "absolute",
                top: 6,
                right: 6,
                backgroundColor: "#eeeeee",
                borderRadius: "50%",
              },
            }}
            viewButtonProps={viewButtonProps}
            editButtonProps={editButtonProps}
            deleteButtonProps={deleteButtonProps}
          />
          <img
            alt="power meter"
            src="/images/item-meter.png"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </div>
      }
    >
      <Card.Meta
        title={
          <Typography.Title
            level={5}
            ellipsis={{ tooltip: name }}
            style={{ margin: 0 }}
          >
            {name}
          </Typography.Title>
        }
        description={
          <Typography.Text type="secondary" ellipsis={{ tooltip: description }}>
            {description ?? " "}
          </Typography.Text>
        }
      />
    </Card>
  );
};

export default React.memo(PowerMeterItem);
