import { CaretDownFilled, FilterOutlined } from "@ant-design/icons";
import { useList } from "@refinedev/core";
import { Button, DatePicker, Dropdown, Form, Space, Typography } from "antd";
import BranchSelect from "components/branch/BranchSelect";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FORM_RULES } from "../../constants";

const datePickerProps = {
  style: { minWidth: 200 },
  disabledDate: (current) => current && current > dayjs().endOf("day"),
};

const ChartFilter = ({
  onFinish,
  formProps = {},
  extra = [],
  submitButtonProps = {},
}) => {
  const [dateType, setDateType] = useState("Month");

  // Get the first branch ID and use it as default value
  useList({
    resource: "branches",
    pagination: { pageSize: 1 },
    sorters: [{ field: "id", order: "asc" }],
    queryOptions: {
      onSuccess: ({ data }) => {
        if (data.length) {
          formProps?.form?.setFieldsValue({ branchId: data?.[0]?.id });
          formProps?.form?.submit();
        }
      },
    },
  });

  return (
    <Form
      layout="vertical"
      initialValues={{
        usageMonth: dayjs(),
        usagePeriod: [dayjs().startOf("month"), dayjs()],
      }}
      {...formProps}
      onFinish={({ usageMonth, usagePeriod, ...rest }) => {
        if (usageMonth) {
          onFinish?.({
            usageDate: [
              dayjs(usageMonth).clone().startOf("month").format("YYYY-MM-DD"),
              dayjs(usageMonth).clone().endOf("month").format("YYYY-MM-DD"),
            ],
            ...rest,
          });
        } else if (usagePeriod) {
          onFinish?.({
            usageDate: [
              dayjs(usagePeriod?.[0]).format("YYYY-MM-DD"),
              dayjs(usagePeriod?.[1]).format("YYYY-MM-DD"),
            ],
            ...rest,
          });
        } else {
          onFinish?.(rest);
        }
      }}
    >
      <Space wrap>
        <Form.Item
          name={`usage${dateType}`}
          label={
            <Dropdown
              menu={{
                items: [
                  { key: "Month", label: "Month" },
                  { key: "Period", label: "Period" },
                ],
                onClick: ({ key }) => setDateType(key),
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space align="baseline">
                  <Typography.Text>{dateType}</Typography.Text>
                  <CaretDownFilled style={{ color: "black" }} />
                </Space>
              </a>
            </Dropdown>
          }
          rules={[FORM_RULES.required]}
        >
          {dateType === "Month" ? (
            <DatePicker picker="month" {...datePickerProps} />
          ) : (
            <DatePicker.RangePicker {...datePickerProps} />
          )}
        </Form.Item>
        <Form.Item name="branchId" label="Branch" rules={[FORM_RULES.required]}>
          <BranchSelect
            style={{ width: 200 }}
            placeholder="Select Branch"
            allowClear={false}
          />
        </Form.Item>
        {extra.map(({ name, children, ...rest }) => (
          <Form.Item key={name} name={name} {...rest}>
            {children}
          </Form.Item>
        ))}
        <Form.Item label=" ">
          <Button
            icon={<FilterOutlined />}
            {...submitButtonProps}
            type="primary"
            htmlType="submit"
          >
            Apply
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default React.memo(ChartFilter);
