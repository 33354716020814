import { Typography } from "antd";
import React from "react";

const NumberText = ({ children }) => {
  return (
    <Typography.Text
      style={{ fontSize: "18px", fontWeight: 700, color: "#999999" }}
    >
      {children}
    </Typography.Text>
  );
};

export default React.memo(NumberText);
