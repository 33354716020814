import {
  ApiOutlined,
  BulbOutlined,
  CopyrightCircleOutlined,
} from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  SaveButton,
  Show,
  useForm,
  useSimpleList,
  useTable,
} from "@refinedev/antd";
import { useShow, useUpdate } from "@refinedev/core";
import {
  List as AntdList,
  Avatar,
  Card,
  Descriptions,
  Drawer,
  Form,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import PowerMeterForm from "components/powerMeter/PowerMeterForm";
import PowerMeterItem from "components/powerMeter/PowerMeterItem";
import SwitchForm from "components/switch/SwitchForm";
import SwitchTypeFilter from "components/switch/SwitchTypeFilter";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { COLORS } from "../../constants";

const getSwitchAvatar = (type) => {
  switch (type) {
    case "Power Socket":
      return (
        <Avatar style={{ backgroundColor: "#0F9D58" }} icon={<ApiOutlined />} />
      );
    case "Light":
      return (
        <Avatar
          style={{ backgroundColor: "#F4B400" }}
          icon={<BulbOutlined />}
        />
      );
    case "Air Conditioner":
      return (
        <Avatar
          style={{ backgroundColor: "#4285F4" }}
          icon={<CopyrightCircleOutlined />}
        />
      );
    default:
      return null;
  }
};

export const RoomShow = () => {
  const { queryResult } = useShow();
  const record = queryResult?.data?.data ?? {};
  const [showCreatePowerMeter, setShowCreatePowerMeter] = useState(false);
  const [showEditPowerMeter, setShowEditPowerMeter] = useState(false);
  const [showCreateSwitch, setShowCreateSwitch] = useState(false);
  const [showEditSwitch, setShowEditSwitch] = useState(false);

  const { mutate: mutateUpdate } = useUpdate();

  const { listProps } = useSimpleList({
    resource: "power_meters",
    filters: {
      permanent: [{ field: "roomId", operator: "eq", value: record?.id }],
    },
    pagination: { mode: "off" },
    queryOptions: { enabled: record?.id > 0 },
  });

  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch: refetchSwitches },
  } = useTable({
    resource: "switches",
    filters: {
      permanent: [{ field: "roomId", operator: "eq", value: record?.id }],
    },
    sorters: { initial: [{ field: "name", order: "asc" }] },
    onSearch: (params) => {
      return Object.keys(params).map((field) => ({
        field,
        operator: "eq",
        value: params[field],
      }));
    },
    queryOptions: { enabled: record?.id > 0 },
  });

  const {
    formProps: formPropsCreatePowerMeter,
    saveButtonProps: saveButtonPropsCreatePowerMeter,
  } = useForm({
    resource: "power_meters",
    action: "create",
    redirect: false,
    onMutationSuccess: () => setShowCreatePowerMeter(false),
  });

  const {
    formProps: formPropsEditPowerMeter,
    saveButtonProps: saveButtonPropsEditPowerMeter,
    setId: setEditPowerMeterId,
  } = useForm({
    resource: "power_meters",
    action: "edit",
    redirect: false,
    onMutationSuccess: () => setShowEditPowerMeter(false),
  });

  const {
    formProps: formPropsCreateSwitch,
    saveButtonProps: saveButtonPropsCreateSwitch,
  } = useForm({
    resource: "switches",
    action: "create",
    redirect: false,
    onMutationSuccess: () => setShowCreateSwitch(false),
  });

  const {
    formProps: formPropsEditSwitch,
    saveButtonProps: saveButtonPropsEditSwitch,
    setId: setEditSwitchId,
  } = useForm({
    resource: "switches",
    action: "edit",
    redirect: false,
    onMutationSuccess: () => setShowEditSwitch(false),
  });

  useEffect(() => {
    const timer = setInterval(() => {
      refetchSwitches?.();
    }, 8 * 1000);

    return () => clearInterval(timer);
  }, []);

  const toggleSwitch = (record, status) =>
    mutateUpdate({
      resource: "switches",
      id: record?.id,
      values: { ...record, status },
      successNotification: false,
    });

  return (
    <>
      <Space direction="vertical" size={0} style={{ display: "flex" }}>
        <Show>
          <Space direction="vertical" style={{ display: "flex" }}>
            <Space style={{ marginBottom: 10 }}>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                {record?.name}
              </Typography.Title>
              <div>
                <Tag color={COLORS[record?.status]}>{record?.status}</Tag>
                <Tag
                  color={record?.autoMode ? "blue" : "default"}
                >{`Auto Mode ${record?.autoMode ? "On" : "Off"}`}</Tag>
              </div>
            </Space>

            <Typography.Text type="secondary" italic={!record?.description}>
              {record?.description ?? "No description on this room."}
            </Typography.Text>
          </Space>
        </Show>

        <Descriptions
          title="Power Meters"
          style={{ marginTop: 36, paddingLeft: 4 }}
          extra={[
            <CreateButton
              key="create"
              onClick={() => setShowCreatePowerMeter(true)}
            />,
          ]}
        />
        <AntdList
          grid={{
            gutter: 0,
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 6,
            xxl: 8,
          }}
          {...listProps}
          rowKey="id"
          size="small"
          renderItem={(item) => (
            <AntdList.Item style={{ paddingLeft: 0 }}>
              <PowerMeterItem
                {...item}
                viewButtonProps={{ hidden: true }}
                editButtonProps={{
                  onClick: () => {
                    setEditPowerMeterId(item?.id);
                    setShowEditPowerMeter(true);
                  },
                }}
              />
            </AntdList.Item>
          )}
        />

        <Descriptions
          title="Switches"
          style={{ marginTop: 36, paddingLeft: 4 }}
          extra={[
            <CreateButton
              key="create"
              onClick={() => setShowCreateSwitch(true)}
            />,
          ]}
        />
        <Card>
          <Space direction="vertical" size={12} style={{ display: "flex" }}>
            <Form
              {...searchFormProps}
              onValuesChange={() => searchFormProps?.form?.submit()}
            >
              <Form.Item name="type" noStyle>
                <SwitchTypeFilter />
              </Form.Item>
            </Form>
            <Table {...tableProps} rowKey="id" size="small" showHeader={false}>
              <Table.Column
                dataIndex="type"
                title="Type"
                align="center"
                width={50}
                render={(value) => getSwitchAvatar(value)}
              />
              <Table.Column dataIndex="name" title="Name" width={160} />
              <Table.Column
                dataIndex="description"
                title="Description"
                render={(value) => (
                  <Typography.Text type="secondary">{value}</Typography.Text>
                )}
              />
              <Table.Column
                dataIndex="aircondCelsius"
                title="Aircond Celsius"
                align="right"
                width={50}
                render={(value) => (value ? `${value}\u00b0C` : "")}
              />
              <Table.Column
                dataIndex="autoOnTimer"
                title="Auto On"
                width={110}
                render={(value) => (
                  <Tag color="blue">{`Auto On: ${pluralize(
                    "min",
                    value,
                    true
                  )}`}</Tag>
                )}
              />
              <Table.Column
                dataIndex="autoOffTimer"
                title="Auto Off"
                width={110}
                render={(value) => (
                  <Tag color="default">{`Auto Off: ${pluralize(
                    "min",
                    value,
                    true
                  )}`}</Tag>
                )}
              />
              <Table.Column
                key="actions"
                width={80}
                render={(_, row) => {
                  const control = (
                    <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      checked={row?.status}
                      loading={row?.isLoading}
                      disabled={!!record?.autoMode}
                      onChange={(checked) => toggleSwitch(row, checked)}
                    />
                  );

                  return (
                    <Space>
                      {record?.autoMode ? (
                        <Tooltip title="Not applicable for auto mode">
                          {control}
                        </Tooltip>
                      ) : (
                        control
                      )}
                      <EditButton
                        size="small"
                        type="text"
                        hideText
                        onClick={() => {
                          setEditSwitchId(row?.id);
                          setShowEditSwitch(true);
                        }}
                      />
                      <DeleteButton
                        resource="switches"
                        recordItemId={row?.id}
                        size="small"
                        type="text"
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </Space>
        </Card>
      </Space>

      <Drawer
        title="Create Power Meter"
        open={showCreatePowerMeter}
        onClose={() => {
          setShowCreatePowerMeter(false);
          formPropsCreatePowerMeter?.form?.resetFields();
        }}
        extra={[<SaveButton key="save" {...saveButtonPropsCreatePowerMeter} />]}
      >
        <PowerMeterForm
          {...formPropsCreatePowerMeter}
          initialValues={{ roomId: record?.id }}
          layout="vertical"
        />
      </Drawer>

      <Drawer
        title="Edit Power Meter"
        open={showEditPowerMeter}
        onClose={() => {
          setShowEditPowerMeter(false);
          formPropsEditPowerMeter?.form?.resetFields();
        }}
        extra={[<SaveButton key="save" {...saveButtonPropsEditPowerMeter} />]}
      >
        <PowerMeterForm {...formPropsEditPowerMeter} layout="vertical" />
      </Drawer>

      <Drawer
        title="Create Switch"
        open={showCreateSwitch}
        onClose={() => {
          setShowCreateSwitch(false);
          formPropsCreateSwitch?.form?.resetFields();
        }}
        extra={[<SaveButton key="save" {...saveButtonPropsCreateSwitch} />]}
      >
        <SwitchForm
          {...formPropsCreateSwitch}
          initialValues={{ roomId: record?.id }}
          layout="vertical"
        />
      </Drawer>

      <Drawer
        title="Edit Switch"
        open={showEditSwitch}
        onClose={() => {
          setShowEditSwitch(false);
          formPropsEditSwitch?.form?.resetFields();
        }}
        extra={[<SaveButton key="save" {...saveButtonPropsEditSwitch} />]}
      >
        <SwitchForm {...formPropsEditSwitch} layout="vertical" />
      </Drawer>
    </>
  );
};
