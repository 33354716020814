import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { SWITCH_TYPES } from "../../constants";

const SwitchTypeFilter = ({ value, onChange, containerStyle = {} }) => {
  const [selectedValues, setSelectedValues] = useState(value ?? []);

  useEffect(() => {
    onChange?.(selectedValues);
  }, [selectedValues]);

  const toggleButton = (value) => {
    const index = selectedValues.indexOf(value);

    if (index < 0) {
      setSelectedValues((prev) => [...prev, value]);
    } else {
      setSelectedValues((prev) => prev.filter((item) => item !== value));
    }
  };

  return (
    <Space style={containerStyle} wrap>
      <Button
        shape="round"
        type={selectedValues.length === 0 ? "primary" : "default"}
        onClick={() => setSelectedValues([])}
      >
        ALL
      </Button>

      {SWITCH_TYPES.map((item, idx) => (
        <Button
          key={`${idx}`}
          shape="round"
          type={selectedValues.includes(item) ? "primary" : "default"}
          onClick={() => toggleButton(item)}
        >
          {item}
        </Button>
      ))}
    </Space>
  );
};
export default React.memo(SwitchTypeFilter);
